























import { i18n } from "@/helpers/i18n";
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import FastGameMenu from '@/components/menus/FastGameMenu.vue'
import Common from '@/mixins/Common.vue'
import GameboxNoLoad from '@/pages/games/modules/GameboxNoLoad.vue'
import GameBox from '@/pages/games/modules/GameBox.vue'
import axios from 'axios'

const cookies = require('js-cookie')
const UIMutation = namespace('ui').Mutation
const SettingsGetter = namespace('settings').Getter
const AccountGetter = namespace('account').Getter
const AccountAction = namespace('account').Action

const AppProps = Vue.extend({
  props: {},
  mixins: [Common],
  data: () => {
    return {
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent),
      isAppleDevice: /iphone|ipod|ipad/i.test(navigator.userAgent),
      gameOverlayEnabled: true,
      logoEnabled: false,
      gameBoxText: '',
      primaryButtonText: '',
      primaryButtonHref: '',
      primaryButtonShowDepositModal: false,
      secondaryButtonText: '',
      secondaryButtonHref: '',
      isAccountLoaded: false,
      isGameLoaded: false,
      windowOrientation: window.innerWidth > window.innerHeight ? "landscape" : "portrait"
    }
  },
  methods: {
    gameLoad() {
        this.isAccountLoaded = true;
        let url = this.getGamesRepoUrl + `/rocketslot.html?p=${this.isMobile ? 'mobile' : 'desktop'}&_=` + Math.random().toString(36).slice(2);
        let that = this;
        setTimeout(() => this.logoEnabled = true, 500);
        setTimeout(() => this.logoEnabled = false, 3000);
        setTimeout(() => {
          this.gameOverlayEnabled = false;
          let gameBox = document.getElementById('third_party_game');
          gameBox.innerHTML = '<iframe class="game_holder_if" id="iframe_holder" src="'+url+'" frameborder="0" scrolling="no" v-if="'+this.userToken+'"> <p>{{ $t("your_browser_does_not") }}</p> </iframe>';
          that.initRocketSlot();
          this.isGameLoaded = true;
          if (this.isMobile && !this.isAppleDevice) {
            let iframe = document.querySelector('#third_party_game>iframe')
            iframe.setAttribute('webkitAllowFullScreen', 'true')
            iframe.setAttribute('mozAllowFullScreen', 'true')
            let div = document.createElement('div')
            div.style.cssText = 'top: 0; left: 0; right: 0; bottom: 0; position: fixed'
            div.addEventListener('click', () => {
              if (iframe) {
                iframe.requestFullscreen()
              }
              div.remove()
            })
            document.body.appendChild(div);
          }
        },3500);
    },
    initRocketSlot() {
        window.addEventListener("message", (event) => {
        if(event.data === 'hook_initialized') {
          this.sendMessageToIframe({
            sender: 'games-client', 
            action: 'init_token', 
            token: this.userToken, 
            casino_code: cookies.get('casino_code'), 
            session: cookies.get('session'), 
            user_id: cookies.get('userId')
          });
          
          this.sendMessageToIframe({sender: 'games-client', action: 'init_lang', lang: i18n.locale, supportedLanguages: process.env.VUE_APP_STRAPI_LANGUAGES})
        }

        if(event.data === 'open_deposit_popup') {
          //@ts-ignore
          $("#header-control-deposit").click();
        }

        if(event.data === 'update_balance') {
          this.getUserBalance();
        }
      });
    },
    handleOrientationChange() {
      this.windowOrientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";
      if (!this.isGameLoaded && this.windowOrientation === "landscape") {
        this.gameLoad();
      }
    },
    sendMessageToIframe(message) {
      // @ts-ignore
      document.getElementById('iframe_holder').contentWindow.postMessage(message, this.getGamesRepoUrl);
    },
    calculateVH() {
      //@ts-ignore
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    },
    calculateFrameSize() {
      if (!(/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent))) {
        let gameContainerHeight = document.querySelector('#third_party_game').getBoundingClientRect().height;
        // @ts-ignore
        document.querySelector('.main_container').style.maxWidth = ((gameContainerHeight * 1850) / 1050) + 'px';
      }
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Rocket Slot with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Rocket Slot`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  watch: {
    userToken: function (newToken) {
      if(newToken && this.isLoggedIn) {
        this.gameLoad()
      }
    },
    depositIncomingCryptoAmount: () => {
     //@ts-ignore
     this.sendMessageToIframe({sender: 'games-client', action: 'refresh_balance'})
    }
  },
  async beforeCreate() {
      try {
        let res = await axios.get('/api/rocketslot/ruleset')
        if(res.data.result) {
          return res.data.result
        } else {
          throw new Error('Invalid rocketslot ruleset response')
        }
      }
      catch(e) {
        this.$router.push('maintenance')
        console.log("Error trying to get the rocketslot ruleset")
        return e
      }
  },
  created () {
    // @ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(false)
  },
  beforeDestroy () {
    //@ts-ignore
    document.querySelector('.main_container').style.removeProperty('max-width');
  },
  async mounted () {
    this.handleOrientationChange()
    window.onresize = () => { this.handleOrientationChange() }
    $("#ultra_container").addClass("heightlock");
    this.calculateVH();
    this.calculateFrameSize();
    window.addEventListener('resize', () => {
      this.calculateVH();
      this.calculateFrameSize();
    })
  }
})
@Component({
  components: {
    FastGameMenu,
    GameboxNoLoad,
    GameBox
  }
})
export default class RocketSlot extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @SettingsGetter getPlatformCurrency!: String
  @SettingsGetter getGamesRepoUrl
  @AccountGetter userToken
  @AccountGetter isLoggedIn
  @AccountAction getUserBalance
}
